import * as React from 'react';

import { createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Invite from './pages/Invite';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/invite/:id',
    element: <Invite />
  }
]);

export default router;
