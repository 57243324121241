import * as React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

function Invite() {
  const [searchParams] = useSearchParams();
  const user = searchParams.get('user');
  const account = searchParams.get('account');

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {user} is inviting to you join "{account}".
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom>
          Please download the app and open this link again.
        </Typography>
      </Box>
    </Container>
  );
}

export default Invite;
