import * as React from 'react';
import { Container, Box, Typography } from '@mui/material';

function Home() {
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Spendora
        </Typography>
      </Box>
    </Container>
  );
}

export default Home;
